import './download.css';
import welcome from '../../Assets/welcome.png';
import appstoreButton from '../../Assets/appstore.png';
import playstoreButton from '../../Assets/gplay.png';

const Download = () => {
    return(
    <div className='download-container'>
        <div className='download-image'>
            <img src={welcome} />
        </div>
        <div className='download-text'>
            <p className='arrow'>←</p>
            <p className='download_now'>Download<br/>Now</p>
            <div className='download-buttons'>
            <a href="https://apps.apple.com/us/app/socale/id1639270036"><img src={appstoreButton} className='appstore'/></a>
            <a href="https://play.google.com/store/apps/details?id=co.socale.socale"><img src={playstoreButton} className='appstore'/></a>
            </div>
        </div>
    </div>);
};

export default Download;
