import React from 'react';

import HomePage from './HomePage';

import './footer.css'

const LandingPage = () => {
    return(<div className="App">
    <HomePage />

    <footer class="text-center text-lg-start bg-black">
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <div className="links d-none d-lg-block text-col">
          <div className='values'>
          <a href=''>FAQs</a>
          <a href='https://socale.notion.site/Socale-Privacy-Policy-37a3eb72668e42af820213108bc288a5'>Privacy Policy</a>
          <a href='https://socale.notion.site/Socale-Terms-of-Service-c3bb41ba6d3b4f21906191bb1f5bfaeb'>Terms of Service</a>
          <a href=''>Report Bugs</a>
          </div>
        </div>
        <div>
          <a href="https://www.reddit.com/r/Socale/" class="me-4 text-reset"><i class="fab fa-reddit text-col"></i></a>
          <a href="https://www.instagram.com/socale.co/" class="me-4 text-reset"><i class="fab fa-instagram text-col"></i></a>
          <a href="https://www.linkedin.com/company/socale/" class="me-4 text-reset"><i class="fab fa-linkedin text-col"></i></a>
          <a href="https://discord.com/invite/9v3G6xYHFB" class="me-4 text-reset"><i class="fab fa-discord text-col"></i></a>
        </div>
      </section>
      <section class="d-flex justify-content-center p-2 border-bottom">
        <div class="me-5 d-none d-lg-block text-col">
          © 2022 Socale, All rights reserved
        </div>
      </section>
    </footer>
  </div>);
}
export default LandingPage;