import './working.css';
import step1 from '../../Assets/step1.png';
import step2 from '../../Assets/step2.png';
import step3 from '../../Assets/step3.png';

const Working = () => {
    return(
        <div className='working-container'>
            <h1 className='title'>How it <span className='under'>Works</span>?</h1>
            <div className='working-steps'>
                <img src={step1} className='step'/>
                <img src={step2} className='step'/>
                <img src={step3} className='step'/>
            </div>
            <a href="https://socale.notion.site/Socale-Algorithm-Explained-9312befaa45f41c2919de8ada38ab88b">
            <button className='button-hero'><b>Learn More</b></button>
            </a>
        </div>
    );
};

export default Working;