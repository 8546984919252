import React from 'react';

import './App.css';

import LandingPage from './Pages/HomePage/LandingPage';
import iosDownload from './Pages/Download/ios';
import instagram from './Pages/External/instagram';
import privacyPolicy from './Pages/legal/privacyPolicy';
import tos from './Pages/legal/tos';
import Verified from './Pages/verified/verified';
import Support from './Pages/support/support';
import androidDownload from './Pages/Download/android';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router basename={`/${process.env.PUBLIC_URL}`}>
      <Switch>
        <Route exact path="/" component={LandingPage} />
      </Switch>
      <Switch>
        <Route exact path="/ios" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/ios_instagram" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/ios1" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/ios2" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/ios3" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/ios4" component={iosDownload} />
      </Switch>
      <Switch>
        <Route exact path="/android" component={androidDownload} />
      </Switch>
      <Switch>
        <Route exact path="/android_instagram" component={androidDownload} />
      </Switch>
      <Switch>
        <Route exact path="/instagram" component={instagram} />
      </Switch>
      <Switch>
        <Route exact path="/privacypolicy" component={privacyPolicy} />
      </Switch>
      <Switch>
        <Route exact path="/tos" component={tos} />
      </Switch>
      <Switch>
        <Route path='/account/verified' component={Verified}/>
      </Switch>
      <Switch>
        <Route path='/support' component={Support}/>
      </Switch>
      </Router>
        );
}

// Commented out the following lines in src/index.js:

export default App;
