import React from "react";
import Analytics from "../../Components/Analytics/analytics";


const androidDownload = () => {
    window.location.replace('https://play.google.com/store/apps/details?id=co.socale.socale');
  return <Analytics/>;
}

export default androidDownload;
