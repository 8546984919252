import React from "react";
import check from "./check.png";
import logo from "./logo.png";
import planet from "./planet.png";

import "./verified.css";

const Verified = () => {
    
    function redirection() {
        var userAgent = window.navigator.userAgent;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
            window.location = "socale://"    // This is your app name. make sure it's like URLScheme Name
        }
    }

    return (
        <div className="verified">
        <div class="card">
        <script>
            {redirection()}
        </script>
            <div class="card-img">
            <img src={logo} alt="" className="vlogo"/>
            </div>
            <div class="card-body">
                <img src={planet}></img>
                <h5 class="card-title">You're Verified!</h5>
                <p class="card-text">Your Socale account is almost ready to match up with thousands of students. <br/><br/> Next, follow the in-app 
                instructions to complete the onboarding process.</p>
            </div>
        </div>
        </div>
    );
};

export default Verified;
