import React from 'react';
import Button from '../../Components/Button/Button';

import './Banner.css';

const Banner = () => (
    <div className='banner text-center'>
        <h1 className='title banner-text'>Discover And Build Your College<mark>Network</mark> <span className='highlighted'>Network</span></h1>
        <p className='subtitle banner-text'><strong>UCSD's</strong> first semi-professional networking platform that connects like-minded individuals.</p>
        <Button href="#contact"><b>Download the App</b></Button>
    </div>
)

export default Banner;